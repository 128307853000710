@import '~react-pro-sidebar/dist/scss/variables';
@import "../variables";
.pro-sidebar {
    width: 350px;
    height: auto !important;
    min-width: 350px;
    color: black;

    .pro-sidebar-inner {
        background-color: rgb(255 255 255 / 90%);
        height: 100%;
        position: relative;
        z-index: 101;
    }

    .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
        background-color: rgb(255 255 255 / 0%);
        color: black;

    }

    // .pro-menu-item {
    //   &:hover {
    //     text-decoration: underline;// Change hover background color
    //   }
    // }
  
    .pro-menu-item.active {
       text-decoration: underline; // Change active item background color
    }

    .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
        border-color: black; 
    }
    
    // .pro-sub-menu {
    //   .pro-menu-item {
    //     background-color: #f68326; // Change submenu item background color
    //   }
    // }
    .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
        color: black;
        //font-size: 12px;
    }
    
    .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
        border-color: black;
    }
  }


