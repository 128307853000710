@import "../mixins";
@import "../variables";
@import '~react-pro-sidebar/dist/scss/variables';

.listItem {
    font-size: 12px !important;
}

.listItem:hover {
    color: $sg-blue !important;
}