@import "../mixins";
@import "../variables";

.loginTitle {
  @include display-flex();
  @include justify-content-center();
  font-size: 25px;
  margin-bottom: 45px;
  margin-top: 22px;
  color: #ffffff;
}

.loginLogo {
  width: calc(35vw);
  cursor: pointer;
  display: block;
}

.warroomLogo {
  width: 170px;
  cursor: pointer;
  margin-bottom: 50px;
}