* {
  font-weight: 700;
  color: #ffffff;
}

body {
  margin: 0;
  font-family: "Benton Sans Low-DPI",Arial,Helvetica,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //background-image: linear-gradient(#727273, #434c4f);
  background-size: 30px 30px;
  background-image: linear-gradient(to right, #31bbdf 1px, #00000096 1px), linear-gradient(to bottom, #31bbdf 1px, #00000096 1px);
  // background: linear-gradient(to top left, #31bbdf, black), /* Gradient from light blue to dark blue */
  //             repeating-linear-gradient(
  //               55deg,
  //               #31bbdf 0,
  //               black 10px,
  //               black 10px,
  //               black 20px
  //             ); /* Checkered pattern */
  background-blend-mode: multiply; /* Blend the gradient and the pattern */
  height: 100vh; 
 //background-size: 100vw 100vh
  //background-image: url(../src/assets/BG.svg);
}

::-webkit-input-placeholder {
  text-align: left;
  line-height: normal;
}

:-moz-placeholder { /* Firefox 18- */
  text-align: left;  
  line-height: normal;
}

::-moz-placeholder {  /* Firefox 19+ */
  text-align: left;  
  line-height: normal;
}

:-ms-input-placeholder {  
  text-align: left; 
  line-height: normal;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.state-offshore.state-offshore-top {
  fill: #31bbdf;
}

.state-offshore.state-offshore-right {
  fill: #31bbdf;
}